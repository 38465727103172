import { Button, Field, makeStyles, Textarea, tokens } from '@fluentui/react-components';
import * as React from 'react';
import { useState } from 'react';

/* global HTMLTextAreaElement */

interface TextInsertionProps {
  insertText: (text: string) => void;
}

const useStyles = makeStyles({
  instructions: {
    fontWeight: tokens.fontWeightSemibold,
    marginTop: '20px',
    marginBottom: '10px'
  },
  textPromptAndInsertion: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textAreaField: {
    marginLeft: '20px',
    marginTop: '30px',
    marginBottom: '20px',
    marginRight: '20px',
    maxWidth: '50%'
  }
});

const TextInsertion: React.FC<TextInsertionProps> = (props: TextInsertionProps) => {
  const [text, setText] = useState<string>('Some text.');

  const handleTextInsertion = async () => {
    props.insertText(text);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const styles = useStyles();

  return (
    <div className={styles.textPromptAndInsertion}>
      <Field className={styles.textAreaField} size="large" label="Enter text to be inserted into the document.">
        <Textarea size="large" value={text} onChange={handleTextChange} />
      </Field>
      <Field className={styles.instructions}>Click the button to insert text.</Field>
      <Button appearance="primary" disabled={false} size="large" onClick={handleTextInsertion}>
        Insert text
      </Button>
    </div>
  );
};

export default TextInsertion;
